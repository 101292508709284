<template>
  <b-container>
    <b-modal
      ref="googleSecondSignIn"
      ok-only
      @hidden="retrySignIn('Google')"
      hide-header-close
      @ok="retrySignIn('Google')"
      ok-title="Sign me in"
      >An account already existed with your email address, but we've gone ahead and linked your
      Google login with your existing account. Due to the way Google authentication works, we now
      need to ask you to sign-in again.
    </b-modal>
    <div>
      <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
      <div v-if="!message" class="text-center">
        <Busy primary :size="4" />
        <p class="mt-4 mb-2 text-primary">Preparing sign-in</p>
      </div>
      <h4 v-if="message">Oh no. Looks like something went wrong.</h4>
      <p>{{ message }}</p>
      <p v-if="message"><router-link :to="{ name: 'sign-in' }">Sign in page</router-link></p>
      <p v-if="message"><router-link :to="{ name: 'sign-up' }">Sign up page</router-link></p>
    </div>
  </b-container>
</template>

<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
import Console from "@/console";
import { EventBus } from "@/components/eventbus";
import { cognito } from "@/config/reducerproperties";
import CognitoAuth from "@/cognito/cognitoauth";
import Busy from "@/components/Busy";
import axios from "axios";
var qs = require("qs");

export default {
  name: "Sign-in",
  components: {
    Busy
  },
  mounted: function () {
    var error = this.$route.query.error;
    if (error) {
      var error_description = this.$route.query.error_description;
      // This Error occurs when linking an external account to an existing account
      // Re-initiating a new sign-in completes sign-in on the second attempt
      if (
        error_description.includes("Already found an entry for username") &&
        error_description.includes("Xero_")
      ) {
        this.retrySignIn("Xero");
      } else if (
        error_description.includes("Already found an entry for username") &&
        error_description.includes("Google_")
      ) {
        this.$refs["googleSecondSignIn"].show();
      } else if (
        error_description.includes("Already found an entry for username") &&
        error_description.includes("Quickbooks_")
      ) {
        this.retrySignIn("Quickbooks");
      } else {
        this.message = error + (error_description ? ": " + error_description : "");
      }
    } else {
      Console.log("Using code: ", this.$route.query.code);
      let config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      };
      let data = qs.stringify({
        grant_type: "authorization_code",
        client_id: cognito.clientId,
        code: this.$route.query.code,
        redirect_uri: cognito.callBackUrl
      });
      var URL = cognito.endPoint + "/oauth2/token";
      axios
        .post(URL, data, config)
        .then((result) => {
          CognitoAuth.createUserSession(
            result.data.access_token,
            result.data.id_token,
            result.data.refresh_token
          );
          EventBus.$emit("user-signed-in");
        })
        .catch((err) => {
          this.message = "Something went wrong whilst trying to sign-in " + err;
        });
    }
  },
  data() {
    return {
      title: "Reducer",
      message: null
    };
  },
  methods: {
    retrySignIn(provider) {
      var signInUrl =
        cognito.endPoint +
        "/oauth2/authorize?identity_provider=" +
        provider +
        "&redirect_uri=" +
        cognito.callBackUrl +
        "&response_type=code&client_id=" +
        cognito.clientId +
        "&scope=openid";
      Console.log("Retrying sign-in for social provider");
      window.location.href = signInUrl;
    }
  }
};
</script>